<template>
    <div class="dollchiki">
        <div class="blue-light"></div>

        <div class="head" :class="$mq">
            <a href="https://www.instagram.com/kibanovadolls/" target="_blanc" class="insta" :class="$mq"><span>22000+</span><br/>подписчиков</a>
            <div class="dc-logo" :class="$mq">
                <KImage :src="require('@/assets/dollchiki/DC-logo.svg')"/>
                <p>Закрытый онлайн-клуб</p>
            </div>
            <a class="login" :class="$mq">Войти</a>
        </div>

        <!-- VIDEO -->
        <video-background
            :poster="require('@/assets/dollchiki/Cover_head.jpg')"
            src="/files/DollChiki/Head_1080p.mp4"
            :sources="[
                {src: '/files/DollChiki/Head_720p.mp4', res: 900, autoplay: true},
                {src: '/files/DollChiki/Head_360p.mp4', res: 638, autoplay: true}
            ]"
            class="video-bg"  :class="$mq"
            >
            <div class="video-container">
            <div class="video-fill"></div>
            
            <div class="dc-cont" :class="$mq">
                <h1 :class="$mq">Выведи свое творчество на&nbsp;новый уровень за&nbsp;950руб/месяц</h1>

                <div class="dc-adv onlycomp" :class="$mq">
                    <div class="dc-adv-block" :class="$mq">
                        <p>Новый вызов <br> каждый месяц</p>
                    </div>
                    <div class="dc-adv-block analys" :class="$mq">
                        <p>Получи разбор <br> своей работы</p>
                    </div>
                    <div class="dc-adv-block support" :class="$mq">
                        <p>Поддержим, <br> замотивируем</p>
                    </div>
                </div>
            </div>

            <div class="video-bottom" :class="$mq"></div>
            </div>
        </video-background>

        <div class="dc-cont" :class="$mq">
                <div class="dc-adv onlymob" :class="$mq">
                    <div class="dc-adv-block" :class="$mq">
                        <p>Новый вызов <br> каждый месяц</p>
                    </div>
                    <div class="dc-adv-block analys" :class="$mq">
                        <p>Получи разбор <br> своей работы</p>
                    </div>
                    <div class="dc-adv-block support" :class="$mq">
                        <p>Поддержим, <br> замотивируем</p>
                    </div>
                </div>
            </div>

        <div class="dc-star" :class="$mq"><KImage :src="require('@/assets/dollchiki/DC-star.svg')" width="100%"/></div>

        <!-- WHO FOR -->    
        <div class="dc-cont" :class="$mq">            
            <h2 :class="$mq">Для всех, кому нравится лепить кукол</h2>
            <div class="dc-who" :class="$mq">
                <div class="red-light right" :class="$mq"></div>
                <div class="dc-who-text" :class="$mq">
                    <div :class="$mq">
                        <h3>Для начинающих</h3>
                        <p>Перестанешь бояться пластика и наконец начнешь лепить, приобретая фундаментальные знания на практике. </p>
                    </div>
                    <div class="exp" :class="$mq">
                        <h3>Для опытных</h3>
                        <p>Получи конструктивную обратную связь и возможность показать себя, неминуемый рост и кайф от результата. </p>
                    </div>
                    <div class="try" :class="$mq">
                        <h3>Вообще не лепишь, но хочешь попробовать</h3>
                        <p>Это место силы как раз для тебя. Реализуй свой творческий потенциал и поверь в себя. Ты всё можешь</p>
                    </div>
                </div>
                <div class="dc-who-img" :class="$mq"><KImage :src="require('@/assets/dollchiki/yesenia.png')" width="100%"/></div>
                <div class="red-light left" :class="$mq"></div>
            </div>
        </div>
        
        <div class="dc-star" :class="$mq"><KImage :src="require('@/assets/dollchiki/DC-star.svg')" width="100%"/></div>

        <!-- WHAT AWAITS YOU -->        
        <div class="dc-what" :class="$mq">
            <div class="gradient blue"></div>
            <div class="dc-cont" :class="$mq">
                <h2 :class="$mq">Что тебя ждет в клубе?</h2>
                <div class="dc-what-cont" :class="$mq">
                    <div class="dc-what-block" :class="$mq">
                        <div class="dc-what-light" :class="$mq"></div>
                        <KImage class="dc-what-img" :class="$mq" :src="require('@/assets/dollchiki/i-video.png')" width="100%"/>
                        <h3>Отработка знаний на практике</h3>
                        <p>Каждую неделю вы получаете новые задания, у вас не будет времени лениться и ныть. С нами вы будете делать, ошибаться, анализировать и становиться круче.</p>
                    </div>
                    <div class="dc-what-block" :class="$mq">
                        <div class="dc-what-light" :class="$mq"></div>
                        <KImage class="dc-what-img" :class="$mq" :src="require('@/assets/dollchiki/i-analys.png')" width="100%"/>
                        <h3>Разбор вашей работы</h3>
                        <p>Профессиональная обратная связь. Будем разбирать ваши ошибки, ваши победы и покажем как сделать еще круче</p>
                    </div>
                    <div class="dc-what-block" :class="$mq">
                        <div class="dc-what-light" :class="$mq"></div>
                        <KImage class="dc-what-img" :class="$mq" :src="require('@/assets/dollchiki/i-chat.png')" width="100%"/>
                        <h3>Общение и поддержка</h3>
                        <p>Быть в кругу единомышленников, обсуждать самые горячие темы. Бесценная возможность делиться. Здесь невозможно стоять на месте.</p>
                    </div>
                    <div class="dc-what-block" :class="$mq">
                        <div class="dc-what-light" :class="$mq"></div>
                        <KImage class="dc-what-img" :class="$mq" :src="require('@/assets/dollchiki/i-add.png')" width="100%"/>
                        <h3>+ Прямые эфиры</h3>
                        <h3>+ Чек-листы</h3>
                        <h3>+ Конкурсы</h3>
                    </div>
                </div>
            </div>
        </div>

        <!-- QUOTE -->
        <div class="dc-quote" :class="$mq">
            <div class="dc-quote-block" :class="$mq">
                <div class="dc-quote-text" :class="$mq">
                    <div class="dc-quote-star" :class="$mq"><KImage :src="require('@/assets/dollchiki/DC-star-blue.svg')" width="100%"/></div>
                    <h2 :class="$mq">Большие перемены начинаются с&nbsp;маленьких решений. </h2>
                    <p>Твоя задача в клубе — делать и не стоять на месте. Всё остальное мы берём на себя. Развивайся и двигайся к своей мечте вместе с нами.</p>
                </div>
                <div class="dc-quote-img" :class="$mq">
                    <KImage :src="require('@/assets/dollchiki/doll-quote.png')" width="100%"/>
                </div>
            </div>

            <div class="pink quote" :class="$mq">Попасть в&nbsp;клуб <div class="pink-heart"></div></div>
        </div>
        

        <div class="dc-blue" :class="$mq">
            <div class="dc-star" :class="$mq"><KImage :src="require('@/assets/dollchiki/DC-star.svg')" width="100%"/></div>
        </div>
        

        <!-- CALENDAR -->
        <div class="dc-calendar" :class="$mq">
            <div class="dc-cont" :class="$mq">
                <h2 :class="$mq">Какой результат ты получишь?</h2>
                <div class="dc-calendar-cont" :class="$mq">
                    <div class="dc-calendar-block" :class="$mq">
                        <div class="dc-calendar-top"></div>
                        <div class="dc-calendar-top right"></div>
                        <p>Слепим анатомичное женское лицо</p>
                        <p class="script">Июль</p>
                    </div>
                    <div class="dc-calendar-block" :class="$mq">
                        <div class="dc-calendar-top"></div>
                        <div class="dc-calendar-top right"></div>
                        <p>Изучим анатомию эмоций. Слепим улыбку из LaDol</p>
                        <p class="script">Август</p>
                    </div>
                    <div class="dc-calendar-block" :class="$mq">
                        <div class="dc-calendar-top"></div>
                        <div class="dc-calendar-top right"></div>
                        <p>Создадим чертеж куклы, стилизуем</p>
                        <p class="script">Сентябрь</p>
                    </div>
                    <div class="dc-calendar-block" :class="$mq">
                        <div class="dc-calendar-top"></div>
                        <div class="dc-calendar-top right"></div>
                        <p>Слепим анатомичное тело</p>
                        <p class="script">Октябрь</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- GO -->
        <div class="dc-go" :class="$mq">
            <div class="gradient grey"></div>
            <div class="dc-cont" :class="$mq">
                <div class="dc-go-cont" :class="$mq">
                    <div class="dc-go-img" :class="$mq"><KImage :src="require('@/assets/dollchiki/martha.png')" width="100%"/></div>
                    <div class="dc-go-text" :class="$mq">
                        <div class="dc-go-star" :class="$mq"><KImage :src="require('@/assets/dollchiki/DC-star.svg')" width="100%"/></div>
                        <h2 :class="$mq">Получи доступ в&nbsp;клуб DollЧики прямо сейчас</h2>
                        <p class='dc-price' :class="$mq">950&#8381; / месяц</p>
                        <div class="pink price" :class="$mq">Попасть в&nbsp;клуб <div class="pink-heart"></div></div>
                        <p class='dc-or' :class="$mq">или</p>

                        <div class="dc-3months" :class="$mq">
                            <p>Подписка на 3 месяца</p>
                            <div class="dc-price-cont">
                                <p class='dc-new'>2550&#8381;</p>
                                <p class="dc-old">2850&#8381;</p>
                            </div>
                            <div class="pink blue">Попасть в клуб <div class="pink-heart crown"></div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="gradient greybrown"></div>

    </div>
</template>

<script>
import KImage from "@/components/Widgets/KImage";
// import KRoundedButton from "@/components/Widgets/KRoundedButton";
// import KIHandFlower from "@/components/Widgets/Icons/KIHandFlower";

export default {
  name: "MainNew",
  components: {
    KImage,
    // KRoundedButton,
    // KIHandFlower
  }
}
</script>

<style scoped>  
    .dollchiki {
        font-size: 0.9em;
        position: relative;
    }

    h1 {
        font-size: 3em;
        text-align: center;
        line-height: 1.2em;
        color: white;
        position: relative;
        z-index: 10;
        margin-bottom: 0.5em;
        width: 100%;
        max-width: 800px;
        box-sizing: border-box;
    }
        h1 span {
            font-family: "TheArtist", sans-serif;
            font-size: 1.5em;
            position: absolute;
            right: -1em;
            bottom: -0.75em;
        }
        h1.halftablet {
            font-size: 2.5em;
            line-height: 1em;
        }
        h1.mobile {
            font-size: 2em;
            line-height: 1em;
        }

    h2 {
        font-size: 3em;
        line-height: 1em;
        text-align: center;
        max-width: 600px;
        margin: 0 auto 1em auto;
        position: relative;
        z-index: 10;
    }
        h2.halftablet {
            font-size: 2.5em;
        }
        h2.mobile {
            font-size: 2em;
        }

    h3 {
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 1.2em;
        margin-bottom: 0.5em;
        line-height: 1.2em;
    }

    .dc-cont {
        width: 100%;
        max-width: 900px;
        margin: 0 auto;
        padding: 0 1em;
        box-sizing: border-box;
    }
        .dc-cont.quoted {
            background-color: #15464b;
        }
    .dc-star {
        width: 2em;
        padding: 3em 0;
        /*height: 2em;
        background-image: url('~@/assets/dollchiki/DC-star.svg');
        background-repeat: no-repeat; */
        margin: 0 auto;
        position: relative;
        z-index: 10;
    }
    .dc-star.mobile  {
        padding: 1em 0;
        width: 1.5em;
    }

    /* HEAD */
    .head {
        display: flex;
        width: 100%;
        max-width: 900px;
        justify-content: space-between;
        align-items: center;
        font-size: 0.9em;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 125;
        padding: 2em 1em;
        box-sizing: border-box;
    }
        .head.mobile {
            justify-content: center;
            padding: 1em;
        }

    .insta {
        line-height: 1em;
        padding-left: 3em;
        background-image: url('~@/assets/dollchiki/insta.svg');
        background-size: 2.2em;
        background-repeat: no-repeat;
        color: white;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
        .insta span {
            font-size: 1.7em;
        }
        .insta:hover {
            transform: scale(1.1);
        }
        .insta.halftablet {
            font-size: 0.8em;
        }
        .insta.mobile {
            display: none;
        }

    a.login {
        padding: 0.1em 1em;
        background-color: rgba(217, 140, 107, .2);
        border-radius: 2em;
        color: white;
        font-weight: bold;
        font-size: 0.9em;
        transition: all 0.3s cubic-bezier(.54, 0, .4, 1.51);
    }
        a.login:hover {
            transform: scale(1.1);
        }
        a.login.mobile {
            position: absolute;
            right: 1em;
            top: 30%;
        }
    
    .dc-logo {
        width: 16em;
        text-align: center;
    }
        .dc-logo.halftablet,
        .dc-logo.mobile {
            width: 12em;
        }
        .dc-logo.halftablet p,
        .dc-logo.mobile p {
            font-size: 0.8em;
        }

    /* VIDEO */
    .video-container {
        height: 100vh;
        display: flex;
        align-items: flex-end;
        position: relative;
    }

    .video-bg {
        height: 100vh;
    }
    .video-bg.mobile {
        height: 100vh;
    }

    .video-fill {
        background-color: #30091c55;
        position: absolute;
        width: 100%;
        height: 100vh;
    }

    .video-bottom {
        background: linear-gradient(#25072e00, #3a271f);
        position: absolute;
        width: 100%;
        height: 10em;
        bottom: 0;
    }

    /* ADVANTAGES */
    .dc-adv {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
        .dc-adv.mobile {
            flex-wrap: wrap;
            justify-content: center;
        }
        .dc-adv.mobile.onlycomp {
            display: none;
        }
        .dc-adv.onlymob {
            display: none;
        }
        .dc-adv.mobile.onlymob {
            display: flex
        }

    .dc-adv-block {
        width: 30%;
        line-height: 1.2em;
        padding: 1em 0 1em 5em;
        box-sizing: border-box;
        background-image: url('~@/assets/dollchiki/DC-icons.svg');
        background-size: 4.5em;
        background-repeat: no-repeat;
        position: relative;
        z-index: 10;
    }
        .dc-adv-block.halftablet {
            padding: 5em 0 0 1em;
            width: 32%;
        }
        .dc-adv-block.mobile {
            padding: 1em 0 1em 5em;
            width: 65%;
            margin-bottom: 1em;
        }
        .dc-adv-block.analys {
            background-position-y: -8.3em;
        }
        .dc-adv-block.support {
            background-position-y: -18em;
        }
        .dc-adv-block::after {
            content: "";
            width: 4.5em;
            height: 4.5em;
            display: block;
            position: absolute;
            background-color: rgba(184, 68, 76, .3);
            border-radius: 10em;
            top: 0;
            left: 2em;
            z-index: -1;
        }
            .dc-adv-block.mobile::after {
                width: 4em;
                height: 4em;
            }

     /*WHO FOR*/
     .dc-who {
         display: flex;
         justify-content: space-around;
         position: relative;
         align-items: center;
     }
        .dc-who.tablet, .dc-who.halftablet {
            justify-content: flex-start;
        }

    .dc-who-text div{
        position: relative;
    }

    .dc-who-text div::after {
         content: "";
         width: 5em;
         height: 5em;
         display: block;
         position: absolute;
         background-image: url('~@/assets/dollchiki/DC-icons.svg'); 
         background-repeat: no-repeat;   
         background-size: 5em; 
         background-position-y: -29em;
         top: -1em;
         right: 1em;
     }
        .dc-who-text div.exp::after {
            background-position-y: -40em;
        }
        .dc-who-text div.try::after {
            background-position-y: -49em;
        }

    .dc-who-text div {
         padding: 2em 2.5em;
         background-color: rgba(255, 255, 255, .1);
         margin-bottom: 2em;
         border-radius: 0.3em;
     }

     .dc-who-text {
         width: 35%;
         position: relative;
         z-index: 1;
     }
        .dc-who-text.tablet {
            width: 50%;
        }
        .dc-who-text.halftablet {
            width: 70%;
        }
        .dc-who-text.mobile {
            width: 90%;
        }
        .dc-who-text p {
            line-height: 1.3em;
        }

     .dc-who-img {
         width: 45%;
         position: relative;
         top: -2em;
     }
        .dc-who-img.tablet, .dc-who-img.halftablet {
            width: 390px;
            position: absolute;
            right: -5%;
            z-index: 0;
        }
        .dc-who-img.halftablet {
            right: -30%;
        }
        .dc-who-img.mobile {
            width: 150px;
            position: absolute;
            right: -20%;
            top: -13em;
            z-index: 0;
        }

     .red-light, .blue-light {
         width: 1000px;
         height: 1000px;
         position: absolute;
         z-index: 1;         
     }
        .red-light.halftablet, .blue-light.halftablet,
        .red-light.mobile, .blue-light.mobile {
            display: none;
        }
        .red-light {
            background: radial-gradient(circle 500px, rgba(184, 68, 76, 0.8), transparent 100%);
            z-index: 10;
            right: -100%;
            top: -100%
        }
        .red-light.left {
            left: -100%;
            top: 30%;
        }
    .blue-light {
        background: radial-gradient(circle 500px, rgba(58, 178, 210, 0.8), transparent 100%);
        top: -15%;
        left: -20%;
    }

    .gradient {
        position: absolute;
        width: 100%;
        height: 10em;
        z-index: 0;
    }
        .gradient.blue {            
            background: linear-gradient(#3a271f00, #15464b);
            top: -10em;
        }

        .gradient.grey {
            background: linear-gradient(#15464b00, #1f1f1f);
            top: -10em;
        }

        .gradient.greybrown {
            background: linear-gradient(#1f1f1f, #3a271f00);
        }

    /* WHAT AWAITS */
    .dc-what, .dc-quote, .dc-calendar {
        background-color: #15464b;
        position: relative;
    }

    .dc-what h3 {
        font-size: 1.5em;
        position: relative;
        z-index: 10;
    }
        .dc-what.halftablet h3, .dc-what.mobile h3{
            font-size: 1.2em;
        }
        .dc-what.mobile h3 {
            position: relative;
            top: -1em;
        }
        .dc-what.mobile p {
            position: relative;
            top: -1em;
        }

    .dc-what-cont{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .dc-what-block {
        width: 38%;
        margin-bottom: 3em;
        position: relative;
    }
        .dc-what-block.halftablet {
            width: 40%;
        }
        .dc-what-block.mobile{
            width: 90%;
            margin-bottom: 1em;
        }
    .dc-what p {
        line-height: 1.4em;
    }
    .dc-what-img {
        position: relative;
        z-index: 1;
    }
    .dc-what-light {
        width: 340px;
        height: 340px;
        position: absolute;
        background: radial-gradient(circle 170px, rgba(48, 191, 206, 0.5), transparent 90%);
        z-index: 0;
        top: -5%;
    }
        .dc-what-light {
            width: 200px;
            height: 200px;
            background: radial-gradient(circle 100px, rgba(48, 191, 206, 0.4), transparent 90%);

        }

    /* QUOTE */
    .dc-quote{
        padding: 3em 0;
    }
    .dc-quote-block {
        max-width: 900px;
        background-color: white;
        margin: 0 auto;
        color: #15464b;
        padding: 4em 5em 6em 5em;
        box-sizing: border-box;
        border-radius: 0.5em;
        position: relative;
        z-index: 20;
    }
        .dc-quote-block.tablet, .dc-quote-block.halftablet, .dc-quote-block.mobile {
            width: 90%;
        }

        .dc-quote-block.halftablet {
            padding: 4em 3em 6em 3em;
        }
        .dc-quote-block.mobile {
            padding: 4em 2em 6em 2em;
        }
    .dc-quote-img {
        width: 402px;
        position: absolute;
        right: -15%;
        top: -20%;
        z-index: 0;
    }
        .dc-quote-img.tablet{
            right: -20%;
            top: -0%;
        }
        .dc-quote-img.halftablet {
            width: 280px;
            right: -10%;
            top: -10%;
        }
        .dc-quote-img.mobile {
            width: 180px;
            right: -10%;
            top: -10%;
        }
    .dc-quote-text {
        width: 80%;        
        position: relative;
        z-index: 1;
    }
        .dc-quote-text.halftablet, .dc-quote-text.mobile {
            width: 100%;
        }

    .dc-quote h2 {
        text-align: left;
        margin: 0 0 0.5em 0;
    }
         .dc-quote.halftablet h2,
         .dc-quote.mobile h2 {
            width: 65%;
            font-size: 1.9em;
            line-height: 1.2em;
            font-family: "Montserrat", sans-serif;
            font-weight: 600;
        }

    .dc-quote p {
        font-size: 1.2em;
        line-height: 1.4em;
        max-width: 600px;
    }
        
        .dc-quote p {
            font-size: 1em;
        }

    div.pink {
        background-color: #d46060;
        color: white;
        padding: 1em 3em;
        border-radius: 10em;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 1.4em;
        line-height: 1em;
        text-align: center;
        box-shadow: 0 0.2em 1em 0.5em #ae373799;
        transition: transform 0.3s cubic-bezier(.54, 0, .4, 1.51);
        position: relative;
        z-index: 20;
        cursor: pointer;
    }
        div.pink.halftablet {
            font-size: 1.2em;
        }
        div.pink:hover {
            transform: scale(1.1);
        }
        div.pink-heart {    
            width: 4em;
            height: 2em;
            position: absolute;
            background-image: url('~@/assets/dollchiki/b-hearts.svg');
            background-repeat: no-repeat;
            background-size: 3em;
            right: -1.5em;
            top: 1em;
        }
        div.pink-heart.crown {
            background-image: url('~@/assets/dollchiki/b-crown.svg');
            height: 4em;
            top: 0;
        }

    div.quote {
        position: absolute;
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }

    .dc-quote-star {
        width: 2em;
        position: absolute;
        top: -2.5em;
        left: -1em;
    }

    .dc-blue {
        background-color: #15464b;
    }

    /*CALENDAR*/ 
    .dc-calendar {
        padding-bottom: 8em;
    }

    .dc-calendar-cont {
        display: flex;
        justify-content: space-between;
    }
        .dc-calendar-cont.halftablet, .dc-calendar-cont.mobile {
            flex-wrap: wrap;            
        }
        .dc-calendar-cont.mobile {
            justify-content: center;
        }

    .dc-calendar-block {
        width: 24%;
        background-color: #d46060;
        padding: 1.5em 2em 4em 1.5em;
        font-size: 1.1em;
        line-height: 1.2em;
        box-sizing: border-box;
        position: relative;
        border-radius: 0.3em;
        z-index: 10;
    }
        .dc-calendar-block.halftablet {
            width: 49%;
            margin-bottom: 1em;
            min-height: 10em;
        }
        .dc-calendar-block.mobile {
            width: 90%;
            margin-bottom: 1em;
        }
        

    .dc-calendar-block p.script {
        font-family: "TheArtist", sans-serif;
        font-size: 3em;
        position: absolute;
        bottom: 0;
        right: 0.5em;
    }

    .dc-calendar-top {
        width: 1em;
        height: 1.2em;
        background-image: url('~@/assets/dollchiki/calendar-i.svg');
        background-repeat: no-repeat;
        background-size: 0.6em;
        position: absolute;
        top: -0.3em;
        left: 10%;
    }
        .dc-calendar-top.right {
            left: 85%;
        }

    /* GO */
    .dc-go {
        background-color: #201f1f;
        position: relative;
    }
        .dc-go.mobile h2 {
            text-align: left;
            width: 80%;
            margin: 0 0 1em 0;
        }

    .dc-go-cont {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
        .dc-go-cont.tablet,
        .dc-go-cont.halftablet {
            justify-content: flex-end;
        }
        .dc-go-cont.mobile {
            justify-content: flex-start;
        }

    .dc-go-text {
        width: 40%;
        position: relative;
        text-align: center;
        z-index: 1;
    }
        .dc-go-text.tablet,
        .dc-go-text.halftablet {
            width: 60%;
        }
        .dc-go-text.mobile {
            width: 90%;
            margin: 0 auto;
            text-align: left;
        }

    .dc-go-img {
        width: 60%;
    }
        .dc-go-img.tablet {
            position: absolute;
            width: 410px;
            z-index: 0;
            left: -0%;
        }
        .dc-go-img.halftablet {
            position: absolute;
            width: 410px;
            z-index: 0;
            left: -20%;
        }
        .dc-go-img.mobile {
            position: absolute;
            width: 210px;
            z-index: 0;
            right: -10%;
            top: -10%;
        }

    .dc-go-star {
        width: 2em;
        position: absolute;
        top: -2.5em;
        left: -1em;
    }
        .dc-go-star.tablet,
        .dc-go-star.halftablet,
        .dc-go-star.mobile {
            display: none;
        }

    .dc-price {
        font-size: 2.75em;
        line-height: 1em;
        margin-bottom: 1em;
    }
        .dc-price.halftablet,
        .dc-price.mobile {
            font-size: 2em;
            font-weight: 600;
        }

    div.pink.price {
        margin-bottom: 2em;
    }
        div.pink.price.halftable,
        div.pink.price.mobile {
            margin-bottom: 1em;
        }
    div.pink.blue {
        background-color: #257f88; 
        box-shadow: 0 0.2em 1em 0.5em #257f8899;
        bottom: -1em;
    }

    .dc-3months {
        background-color: #257f8855;
        border-radius: 0.3em;
        padding: 2em 0 0 0;
    }
        .dc-3months.mobile {
            text-align: center;
        }

    .dc-3months p{
        font-size: 1.5em;
        line-height: 1em;
    }

    .dc-price-cont {
        display: flex;
        justify-content: center;
        text-align: center;
        margin: 1em 0 0 0;
    }

    .dc-old {
        text-decoration: line-through;
    }

    .dc-new {
        font-weight: 700;
        margin-right: 2em;
    }

    .dc-or {
        font-family: "TheArtist", sans-serif;
        font-size: 4em;
        line-height: 1em;
        margin-bottom: 0.5em;
    }
        .dc-or.hafltablet,
        .dc-or.mobile {
            margin-bottom: 0;
            font-size: 3em;
            text-align: center;
        }
</style>